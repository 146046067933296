import TinyUri from 'tiny-uri';
import Turbolinks from 'turbolinks';

import ComposableForm from '@/views/composable_form';
import CustomSelect from '@/views/custom_select';
import PhoneArea from '@/views/phone_area';
import PopperTooltip from '@/views/popper_tooltip';
import PrivacyNote from '@/views/privacy_note';

import axios from '@/helpers/axios';
import { animatedCollapse, animatedExpand } from '@/helpers/animated';

let currentForm;

pageLoad(
  'registrations_show',
  'registrations_create',
  'sessions_check',
  'sessions_show',
  'sessions_create',
  'profiles_show',
  'profiles_create', () => {
    if (!document.body.className.includes('p-orders-v2')) { return; }

    $('.content-column .b-input.select:not(.country_code)')
      .each((_index, node) => new CustomSelect(node));
    process('.order-column');

    $('.content-column a, .progress a[data-order_url_params]').on('click', ({ currentTarget }) => {
      const originalUrl = currentTarget.getAttribute('data-original_url') ||
        currentTarget.href;

      if (!currentTarget.getAttribute('data-original_url')) {
        currentTarget.setAttribute('data-original_url', originalUrl);
      }

      currentTarget.href = applyOrderUrlParams(originalUrl);
    });
  }
);

function process(root) {
  if (currentForm) {
    currentForm.destroy();
  }
  currentForm = buildForm();

  $(root)
    .find('.b-input.select')
    .each((_index, node) => new CustomSelect(node));

  $(root)
    .find('.b-tooltip-icon')
    .each((_index, node) => new PopperTooltip(node));

  $(root)
    .find('.order-details .custom-select')
    .on('custom-select:change', sync);

  $(root)
    .find('input[type="checkbox"]')
    .not('[data-not_triggers_sync]')
    .on('change', sync);

  $(root)
    .find('input[type="checkbox"][data-not_triggers_sync]')
    .on('change', syncCurrentUrl);

  $(root)
    .find('.bill-details-headline')
    .on('click', toggleDetailsHeadline);

  $(root)
    .find('.coupon-code .show-input')
    .on('click', e => {
      e.preventDefault();
      showCouponCode();
    });

  $(root)
    .find('#order_coupon_code')
    .on('change', () => toggleCouponError(false))
    .on('keypress', e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        applyCouponCode();
      }
    });

  $(root)
    .find('.coupon-code .apply')
    .on('click', e => {
      e.preventDefault();
      applyCouponCode();
    });

  $(root)
    .find('.simple_lock button.add')
    .on('click', e => {
      e.preventDefault();
      addSimpleLock();
    });

  $(root)
    .find('button.remove-simple_lock')
    .on('click', e => {
      e.preventDefault();
      removeSimpleLock();
    });

  $(root)
    .find('button.remove-coupon')
    .on('click', e => {
      e.preventDefault();
      removeCoupon();
    });

  $('.mobile-details .paid_months-note').on('click', e => {
    e.preventDefault();

    const $details = $(root).find('.bill-details-headline');
    if (!$details.hasClass('is-expanded')) {
      $details.trigger('click');
    }

    document.querySelector('.b-input.tafiff_id').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  });

  $('.b-input.tafiff_id select')
    .on('change', ({ currentTarget }) => {
      const $note = $('.paid_months-note');
      $note.html(
        $note.data('template')
          .replace('%{MONTHS}', $note.data('paid_months')[currentTarget.value])
      );
    })
    .trigger('change');
}

function buildForm() {
  const pageId = document.body.id;

  if (['sessions_check'].includes(pageId)) {
    // need to make form to eanble submit button in the right menu
    return new ComposableForm('.b-form', {});
  }

  if (['sessions_new', 'sessions_show', 'sessions_create'].includes(pageId)) {
    // need to make form to eanble submit button in the right menu
    return new ComposableForm('.b-form', {
      fields: ['#user_email', '#user_password']
    });
  }

  if (
    [
      'registrations_new',
      'registrations_create',
      'registrations_show',
      'registrations_update'
    ].includes(pageId)
  ) {
    return new ComposableForm('.b-form', {
      fields: ['#user_email', '#user_password'],
      components: [
        new PhoneArea('.b-phone_area', true),
        new PrivacyNote('.b-privacy_note')
      ]
    });
  }

  if (['profiles_show', 'profiles_create'].includes(pageId)) {
    return new ComposableForm('.b-form', {
      fields: [
        ...$('.content-column input.required[type=text]:not(:disabled)'),
        ...$('.order-column .b-input.agreement-confirmation input[type=checkbox]')
      ]
    });
  }
}

function syncCurrentUrl() {
  const url = applyOrderUrlParams(window.location.href);
  window.history.replaceState({ turbolinks: true, url }, '', url);
}

async function sync() {
  const $orderColumn = $('.order-column');
  $orderColumn.addClass('is-syncing');

  const fetchUrl = applyOrderUrlParams($orderColumn.data('fetch_url'));
  syncCurrentUrl();
  const { data } = await axios.get(fetchUrl);

  process($orderColumn.html(data));
  $orderColumn.removeClass('is-syncing');

  $('.b-form').trigger('composable_form:validate');
}

function applyOrderUrlParams(url) {
  const orderFields = $('.order-column')
    .closest('form')
    .serializeArray()
    .filter(({ name }) => name.startsWith('order['));

  return new TinyUri(url)
    .query
    .merge(
      orderFields.reduce((memo, { name, value }) => {
        memo[name] = value;
        return memo;
      }, {})
    ).toString();
}

function toggleDetailsHeadline({ currentTarget }) {
  if (currentTarget.classList.contains('is-expanded')) {
    animatedCollapse(currentTarget.nextSibling);
    currentTarget.classList.remove('is-expanded');
  } else {
    animatedExpand(currentTarget.nextSibling);
    currentTarget.classList.add('is-expanded');
  }
}

async function applyCouponCode() {
  const code = $('#order_coupon_code').val();

  if (!code) {
    toggleCouponError(true);
    return;
  }

  $('.coupon-code .apply').addClass('hidden');
  $('.coupon-code .spinner').removeClass('hidden');
  await sync();
  $('.coupon_code.field_with_errors #order_coupon_code').focus();
}

function showCouponCode() {
  $('.coupon-code .show-input').addClass('hidden');
  $('.coupon-code .input-container').removeClass('hidden');
  $('#order_coupon_code').focus();
}

function toggleCouponError(isError) {
  $('.coupon-code .b-input').toggleClass('field_with_errors', isError);
  $('.coupon-code .error-message').toggleClass('hidden', !isError);
}

function removeCoupon() {
  $('#order_coupon_code').val('');
  sync();
}

function addSimpleLock() {
  $('#simple_lock_product_id').val(
    $('.simple_lock button.add').data('product_id')
  );
  sync();
}

function removeSimpleLock() {
  $('#simple_lock_product_id').val('');
  sync();
}
