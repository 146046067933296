import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Switcher from '@/views/switcher';

let isMenuActive = false;

$(document).on('turbolinks:before-cache', () => {
  if (isMenuActive) {
    toggleMenu();
  }
});

$(document).on('turbolinks:load', () => {
  const $topMenu = $('.b-top_menu, .b-top_menu-v3');
  $topMenu.find('.mobile-trigger').on('click', toggleMenu);

  $(document.body).on('resize:debounced', () => {
    if (isMenuActive && window.innerWidth >= 1200) {
      toggleMenu();
    }
  });

  new Switcher($('.b-top_menu .b-switcher'), switchBackground);
  switchBackground($('.b-switcher .is-active').data('switcher_type'));

  $topMenu.find('.b-locale_switcher .checkbox-current-locale')
    .on('click', async ({ currentTarget }) => {
      const { localeSwitcherModal } = await import(
        /* webpackChunkName: "modal" */
        '@/views/modal/locale_switcher_modal'
      );
      const $target = $(currentTarget);
      localeSwitcherModal(
        $target.data('countries'),
        $target.data('locales'),
        $target.data('default-locale'),
        $target.data('current-country'),
        $target.data('current-locale')
      );
    });
});

function toggleMenu() {
  isMenuActive = !isMenuActive;
  $('.b-top_menu, .b-top_menu-v3').toggleClass('is-mobile-active', isMenuActive);

  if (isMenuActive) {
    disablePageScroll();
    window.scrollTo(0, 0);
  } else {
    enablePageScroll();
  }
}

function switchBackground(type) {
  const color = type === 'website' ? 'red' : 'blue';
  $('.b-top_menu').attr('data-background', color);
}
