import { isMobile } from '@/helpers/mobile_detect';

let swiper = null;

$(document).on('turbolinks:before-cache', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

$(document).on('turbolinks:load', () => {
  const node = document.querySelector('article.boxes_with_slider');
  if (!node) { return; }

  const swiperNode = node.querySelector('.swiper');

  if (isMobile()) {
    initMobileSwiper(swiperNode);
  } else {
    initDesktopSwiper(swiperNode);
  }
});

async function initMobileSwiper(swiperNode) {
  const { default: Swiper, Pagination } = await import('swiper');

  swiper = new Swiper(swiperNode, {
    wrapperClass: 'boxes',
    slideClass: 'b-box',
    slidesPerView: 1,
    spaceBetween: 32, // $container_mobile_padding-new * 2
    modules: [Pagination],
    pagination: {
      el: '.b-slider-pagination',
      modifierClass: 'slider-pagination-',
      bulletClass: 'slider-pagination-bullet',
      bulletActiveClass: 'slider-pagination-bullet-active',
      clickable: true
    }
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
}

async function initDesktopSwiper(swiperNode) {
  const swiperNavigation = swiperNode.querySelector('.slider-navigation');
  if (swiperNode.querySelectorAll('.b-box').length > 4) {
    swiperNavigation.classList.remove('hidden');
  }

  const { default: Swiper, Navigation, Pagination } = await import('swiper');

  swiper = new Swiper(swiperNode, {
    grabCursor: true,
    wrapperClass: 'boxes',
    slideClass: 'b-box',
    slidesPerView: 4,
    spaceBetween: 30,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.b-slider-next',
      prevEl: '.b-slider-prev',
      disabledClass: 'is-disabled'
    },
    pagination: {
      el: '.b-slider-pagination',
      modifierClass: 'slider-pagination-',
      bulletClass: 'slider-pagination-bullet',
      bulletActiveClass: 'slider-pagination-bullet-active',
      clickable: true
    }
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
}
