import WorldMap from '@/views/world_map';
import trackVk from '@/helpers/track_vk';

pageLoad('storages_index', () => {
  const worldMapNode = document.querySelector('.b-world-map');
  if (worldMapNode) {
    new WorldMap(worldMapNode);
    return;
  }

  $('a.read-more').on('click', ({ currentTarget }) => {
    $(currentTarget).addClass('active');
    $('.additional-seo .spoiler').addClass('active');
  });

  const appNode = document.querySelector('#storages_app');
  renderStoragesApp(appNode, {
    mapType: appNode.getAttribute('data-map_type'),
    storages: $(appNode).data('storages'),
    mapDataNode: appNode,
    presetFilterOptions: $(appNode).data('preset_filter_options'),
    isDistricts: $(appNode).data('is_districts'),
    allBoxSizes: $(appNode).data('all_box_sizes')
  });
  trackVk('view_storages');
});

export async function renderStoragesApp(node, props) {
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');
  const { default: App } = await import('@/vue/storages/index');

  return createApp(App, props).mount(node);
}
