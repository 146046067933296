import autosize from 'autosize';

import track from '@/helpers/track';
import PhoneWithCountryCode from '@/views/phone_with_country_code';

$(document).on('turbolinks:load', () => {
  $('.landing_lead_form')
    .toArray()
    .forEach(buildCrmForm);
});

function buildCrmForm(outerNode) {
  if ($('.country_code.custom-select', outerNode).length) {
    new PhoneWithCountryCode(outerNode);
  }

  const $textarea = $('.b-input textarea', outerNode);
  if ($textarea.length) {
    autosize($textarea[0]);
  }

  $('form#new_crm_lead', outerNode)
    .on('submit', e => {
      $(e.currentTarget)
        .find('.b-input:not(.custom-select):not(.textarea)')
        .each((_index, node) => {
          const $node = $(node);
          const $error = $node.find('.error');
          const $input = $node.find('input');

          if ($input.val().trim()) {
            $node.removeClass('field_with_errors');
            $error.addClass('hidden');
          } else {
            $node.addClass('field_with_errors');
            $error.removeClass('hidden');

            e.preventDefault();
            e.stopImmediatePropagation();
          }
        });
    })
    .on('ajax:success', async e => {
      $(e.currentTarget).find('.b-input input, .b-input textarea').val('');
      const { thanksModal } = await import(
        /* webpackChunkName: "modal" */
        '@/views/modal/thanks_modal'
      );
      thanksModal();

      const pathParts = window.location.pathname.split('/');
      const eventLabel = pathParts[pathParts.length - 2] || '';

      track('down_form');
    });
}
