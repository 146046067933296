import SweetScroll from '@/helpers/sweet_scroll';

let swiper = null;

$(document).on('turbolinks:before-cache', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

$(document).on('turbolinks:load', async () => {
  const node = document.querySelector('article.cannot_be_stored');
  if (!node) { return; }

  const { default: Swiper, Navigation, Pagination } = await import('swiper');

  swiper = new Swiper(node.querySelector('.slides'), {
    wrapperClass: 'slides-wrapper',
    slideClass: 'slide',
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.b-slider-next',
      prevEl: '.b-slider-prev',
      disabledClass: 'is-disabled'
    },
    pagination: {
      el: '.b-slider-pagination',
      modifierClass: 'slider-pagination-',
      bulletClass: 'slider-pagination-bullet',
      bulletActiveClass: 'slider-pagination-bullet-active',
      clickable: true
    },
    loop: true
  });
  swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));
});

export async function toCannotBeStored() {
  (await SweetScroll.asyncInstance()).toElement(
    document.querySelector('article.cannot_be_stored'),
    { duration: 750, offset: -40 }
  );
}
