function isYandex() {
  return document.body.getAttribute('data-map_type') === 'ymap';
}

export async function loadMapLibrary() {
  if (isYandex()) {
    const { loadMapLibrary } = await import(/* webpackChunkName: "ymap" */ './maps/yandex/map');
    return await loadMapLibrary();
  } else {
    const { loadMapLibrary } = await import(/* webpackChunkName: "gmap" */ './maps/google/map');
    return await loadMapLibrary();
  }
}

export async function buildMap({ node, mapType, dataNode, ...args }) {
  const geoData = $(dataNode || node).data('geo');
  const zoom = geoData.house ? 14 : (geoData.street ? 12 : 10);
  const initialZoom = parseInt(geoData.initial_zoom) || zoom;

  const marksData = $(dataNode || node).data('storages');

  const geo = {
    latitude: parseFloat(geoData.geo_lat),
    longitude: parseFloat(geoData.geo_lng),
    zoom: initialZoom || zoom,
    initialZoom
  };

  if (mapType === 'ymap' || isYandex()) {
    const { buildMap } = await import(/* webpackChunkName: "ymap" */ './maps/yandex/map');
    return buildMap({ node, geo, marksData, ...args });
  } else {
    const { buildMap } = await import(/* webpackChunkName: "ymap" */ './maps/google/map');
    return buildMap({ node, geo, marksData, ...args });
  }
}
