import Switcher from '@/views/switcher';

let swiper = null;

pageUnload('pages_how_to_choose_box', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

pageLoad('pages_how_to_choose_box', async () => {
  new Switcher('.slide_1 .b-switcher');

  $('[data-switcher_type="personal_storage"]').on('switcher:active', () => {
    $('article.storable_types .b-switcher button[data-switcher_type="personal"]').click();
  });

  $('[data-switcher_type="business_storage"]').on('switcher:active', async () => {
    $('article.storable_types .b-switcher button[data-switcher_type="business"]').click();
    if (swiper) { return; }

    const { default: Swiper, Navigation, Pagination } = await import('swiper');

    swiper = new Swiper('.slide_1 .business_storage .slides', {
      wrapperClass: 'slides-wrapper',
      slideClass: 'slide-outer',
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: '.b-slider-next',
        prevEl: '.b-slider-prev',
        disabledClass: 'is-disabled'
      },
      pagination: {
        el: '.b-slider-pagination',
        modifierClass: 'slider-pagination-',
        bulletClass: 'slider-pagination-bullet',
        bulletActiveClass: 'slider-pagination-bullet-active',
        clickable: true
      },
      on: {
        slideChange() {
          setSwiperIndex(this.activeIndex);
        }
      }
    });
    swiper.changeLanguageDirection(document.documentElement.getAttribute('dir'));

    setSwiperIndex(0);
  });
});

function setSwiperIndex(index) {
  $('.slide_1 .slides .b-slider-pagination').attr('data-index', index);
}
