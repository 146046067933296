import bind from 'bind-decorator';

import View from './view';
import globalHandler from '@/helpers/global_handler';
import showModal from '@/utils/show_modal';

export default class CustomSelect extends View {
  isFocused = false;
  prevValue = null;

  initialize() {
    this.$node.addClass('custom-select');
    this.$select = this.$('select');
    this.$select.hide();

    this.renderPlaceholder();
    this.renderDropdown();

    if (!this.isDisabled) {
      showModal({
        $modal: this.$dropdown,
        $trigger: this.$placeholder,
        $outerNode: this.$node,
        show: this.onShow,
        hide: this.onHide,
        isHidden: () => !this.isFocused,
        clickEventName: 'mousedown'
      });
    }

    $(document).one('turbolinks:before-cache', this.destroy);
  }

  get currentValue() {
    return this.$select.val();
  }

  get $currentOption() {
    return this.$select.find(`option[value='${this.currentValue}']`).first();
  }

  get $label() {
    return this.$('label');
  }

  get isDisabled() {
    return this.$node.hasClass('disabled');
  }

  @bind
  onShow() {
    this.isFocused = true;

    requestAnimationFrame(() => {
      this.$node.addClass('is-focused');
    });
    this.$dropdown.removeClass('hidden');

    globalHandler
      .on('up', this.onPressUp)
      .on('down', this.onPressDown)
      .on('enter', this.onPressEnter);

    this.prevValue = this.currentValue;
  }

  @bind
  onHide() {
    this.isFocused = false;

    requestAnimationFrame(() => {
      this.$node.removeClass('is-focused');
    });
    this.$dropdown.addClass('hidden');

    globalHandler
      .off('up', this.onPressUp)
      .off('down', this.onPressDown)
      .off('enter', this.onPressEnter);

    if (this.prevValue !== this.currentValue) {
      this.$node.trigger('custom-select:change');
    }
  }

  @bind
  onOptionClick({ currentTarget, isSkipModalHide }) {
    this.selectOption(currentTarget.getAttribute('data-value'));

    if (!isSkipModalHide) {
      this.$dropdown.trigger('modal:hide');
    }
  }

  @bind
  onPressEnter() {
    this.$dropdown.trigger('modal:hide');
  }

  @bind
  onPressUp(e) {
    const $newOption = this.$('.option.is-current').first().prev();
    if (!$newOption.length) { return; }

    e.preventDefault();
    e.stopImmediatePropagation();

    this.selectOption($newOption[0].getAttribute('data-value'));
  }

  @bind
  onPressDown(e) {
    const $newOption = this.$('.option.is-current').last().next();
    if (!$newOption.length) { return; }

    e.preventDefault();
    e.stopImmediatePropagation();

    this.selectOption($newOption[0].getAttribute('data-value'));
  }

  @bind
  destroy() {
    this.$placeholder.remove();
    this.$dropdown.remove();
    this.$select.show();
  }

  renderPlaceholder() {
    this.$placeholder = $(`
      <div class="select-placeholder">
        <div class="placeholder-inner"></div>
        <div class="b-svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <g clip-path="url(#clip0_112_999)">
              <path d="M3.68784 1.09443L0.127804 4.65453C0.045398 4.73687 0 4.84679 0 4.96399C0 5.08119 0.045398 5.19111 0.127804 5.27345L0.389915 5.53563C0.560711 5.70623 0.838302 5.70623 1.00884 5.53563L3.99834 2.54612L6.99116 5.53894C7.07357 5.62128 7.18342 5.66675 7.30056 5.66675C7.41782 5.66675 7.52768 5.62128 7.61015 5.53894L7.87219 5.27677C7.9546 5.19436 8 5.08451 8 4.96731C8 4.8501 7.9546 4.74019 7.87219 4.65785L4.30891 1.09443C4.22624 1.01189 4.11587 0.966558 3.99854 0.966818C3.88075 0.966558 3.77044 1.01189 3.68784 1.09443Z" fill="currentColor"/>
            </g>
            <defs>
              <clipPath id="clip0_112_999">
                <rect width="8" height="8" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    `);

    if (this.isDisabled) {
      this.$placeholder.addClass('is-disabled');
      this.$placeholder.find('.b-svg-icon').addClass('is-disabled');
    } else {
      this.$placeholder.prop('tabindex', '0');
    }

    this.syncCurrentOption(true, false);

    this.$placeholder.insertAfter(this.$select);
  }

  renderDropdown() {
    this.$dropdown = $(`
      <div class="dropdown hidden"></div>
    `);

    this.$select.find('option').each((_index, node) => {
      this.$dropdown.append(
        `<div class="option" data-value="${node.value}">${node.innerText}</b>`
      );
    });
    this.syncCurrentOption(false, true);
    this.on('click', '.dropdown .option', this.onOptionClick);

    this.$dropdown.insertAfter(this.$label.length ? this.$label : this.$placeholder);
  }

  selectOption(value) {
    this.$select
      .val(value)
      .trigger('change');
    this.syncCurrentOption();
  }

  syncCurrentOption(isSyncPlceholder = true, isSyncOption = true) {
    if (isSyncPlceholder) {
      const $placeholderInner = $('<div class="placeholder-inner" />')
        .html(this.$currentOption.text());
      const $placeholderInnerText = $placeholderInner.find('.placeholder-inner-text');

      if ($placeholderInnerText.length) {
        $placeholderInner.html($placeholderInnerText.html());
      }

      this.$placeholder
        .find('.placeholder-inner')
        .replaceWith($placeholderInner);
    }

    if (isSyncOption) {
      this.$dropdown
        .find('.option')
        .removeClass('is-current');

      this.$dropdown
        .find(`.option[data-value='${this.currentValue}']`)
        .addClass('is-current');
    }
  }
}
