import imagesloaded from 'imagesloaded';
import Turbolinks from 'turbolinks';

import CustomSelect from '@/views/custom_select';
import PhoneWithCountryCode from '@/views/phone_with_country_code';
import Switcher from '@/views/switcher';
import { buildMap } from '@/views/map';
import { createBaloonApp } from '@/views/maps/balloon';

import { toLandingLeadForm } from '@/articles/landing_lead_form';

import SweetScroll from '@/helpers/sweet_scroll';
import { isMobile } from '@/helpers/mobile_detect';
import track from '@/helpers/track';

const IMAGE_ACTIVE = '/assets/static/b-map/marker-opened.svg';

// let photosSwiper = null;
let mapBoxes = null;
let modal = null;

let map = null;

pageUnload('storages_show', () => {
  // if (photosSwiper) {
  //   photosSwiper.destroy();
  //   photosSwiper = null;
  // }
  if (modal) {
    modal.destroy();
    modal = null;
  }
  if (mapBoxes) {
    mapBoxes.destroy();
    mapBoxes = null;
  }
  if (map) {
    map.destroy();
    map = null;
  }
});

pageLoad('storages_show', async () => {
  const $storagePhoto = $('.swiper.photos .photo');
  if ($storagePhoto.length) {
    imagesloaded($storagePhoto.toArray(), () => initPhotos($storagePhoto));
  }

  new Switcher('article.slide-8 .b-switcher');

  buildSingleStorageMap();

  $('article.slide-1 .short-links a.contacts').on('click', async () => {
    const $switcher = $('article.slide-8 .b-switcher');
    $switcher.find('button.btn-contacts').click();
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.slide-8'), { duration: 750 }
    );
  });
  $('article.slide-1 .short-links a.description').on('click', async () => {
    const $switcher = $('article.slide-8 .b-switcher');
    $switcher.find('button.btn-description').click();
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('article.slide-8'), { duration: 750 }
    );
  });
  $('article.no_available_boxes button.lead').on('click', toLandingLeadForm);
  $('.go-to-lead-form').on('click', toLandingLeadForm);

  if (!isMobile()) {
    const { default: Map } = await import(
      /* webpackChunkName: "map" */ '@/admin/views/map'
    );
    mapBoxes = new Map('.map-boxes', {
      isSelectable: true,
      isResizeMapContainer: false
    });

    $('.boxes-slider .box').on('map:select', ({ currentTarget }) => {
      const $link = currentTarget.tagName === 'A' ?
        $(currentTarget) :
        $(currentTarget).find('a');
      Turbolinks.visit(
        $link.attr('href')
      );
    });
  }

  $('.box-lead')
    .on('ajax:before', ({ currentTarget }) => {
      if (modal) {
        modal.destroy();
        modal = null;
      }

      currentTarget.href = currentTarget.href.replace(
        'CALLTOUCH_SESSION_ID',
        $('#calltouch_session_id').val()
      );
    })
    .on('ajax:success', buildLeadModal);

  $('.size-link:not(.disabled)').on('click', ({ currentTarget }, init) => {
    const $target = $(currentTarget);
    const size = $target.data('size');
    const isDisabled = $target.hasClass('active');

    if (size === 'any' && !init) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('level')) {
        params.delete('level');
        window.location.search = params.toString();
      }
    }

    $target.toggleClass('active');
    $target.siblings().removeClass('active');

    $('.boxes-slider .box').each((_index, node) => {
      const $node = $(node);

      if (isDisabled || size === 'any') {
        $node.removeClass('disabled');
      } else {
        $node.toggleClass('disabled', $node.data('size') !== size);
      }
    });

    if (mapBoxes) {
      if (isDisabled) {
        mapBoxes.setSize(null);
      } else {
        mapBoxes.setSize(size);
      }
    }
  });
  $('.size-link[data-active=true]').trigger('click', true);

  $(document.body).on('resize:debounced', setVideoHeight);
  setVideoHeight();
});

function initPhotos($photos) {
  $photos.magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

  // NOTE: Пока не используем тут свайпер. Сделаю отдельную задачу
  //       на него, пока и без этого нормально смотрится.
  // if ($photos.length > 1) {
  //   const node = `.b-photos.${isMobile() ? 'mobile' : 'desktop'}`;
  //   const { default: Swiper, Navigation } = await import('swiper');
  //   photosSwiper = new Swiper(
  //     node,
  //     {
  //       grabCursor: true,
  //       slidesPerView: isMobile() ? 2 : 1,
  //       spaceBetween: isMobile() ? 12 : 0,
  //       wrapperClass: 'photos-slider',
  //       slideClass: 'photo',
  //       modules: [Navigation],
  //       navigation: {
  //         nextEl: '.photos-slider-next',
  //         prevEl: '.photos-slider-prev'
  //       },
  //       loop: true
  //     }
  //   );
  // }
}

function setVideoHeight() {
  $('.video_camera').each((index, node) => {
    const $node = $(node);
    $node.css('height', Math.round($node.width() / $node.data('ratio')));
  });
}

async function buildLeadModal(_e, html) {
  const { makeModal } = await import(
    /* webpackChunkName: "modal" */
    '@/views/modal/make_modal'
  );

  const $html = $(html);
  const storageId = $html.find('#crm_lead_storage_id').val();
  modal = makeModal({
    $node: $html,
    isDestroyOnHide: true
  });

  modal.$modal.find('form')
    .on('submit', e => {
      $(e.currentTarget).find('.b-input').each((_index, node) => {
        const $node = $(node);
        const $error = $node.find('.error');
        const $input = $node.find('input, select');

        if ($input.val().trim()) {
          $error.addClass('hidden');
        } else {
          $error.removeClass('hidden');

          e.preventDefault();
          e.stopImmediatePropagation();
        }
      });
    })
    .on('ajax:success', async _ => {
      modal.destroy();
      const { thanksModal } = await import(
        /* webpackChunkName: "modal" */
        '@/views/modal/thanks_modal'
      );
      thanksModal();
      track('help_to_choose');
    });

  new PhoneWithCountryCode(modal.$modal);
  new CustomSelect(modal.$modal.find('.box_size.custom-select'));
}

export async function buildSingleStorageMap() {
  const $node = $('#map');
  if (!$node.length) { return; }

  map = (await buildMap({
    node: $node[0],
    yandexMapControls: [],
    isAutoCenter: false,
    isAutoOpen: true
  })).map;
}
